import { Action } from '@ngrx/store';
import { type } from '../../util';
import {
  FetchingPropsEnum,
  FetchingStatusEnum,
  FieldsAsyncValidationEnum,
  IIsEmergencyContactsExist,
  IAddressFields,
  IStepOneFormData,
  IStepTwoFormData,
  NotificationTypesEnum,
  TipTypesEnum,
  UserTypeEnum,
  IValidatedAddressFields
} from './agreement.reducer';
import {
  IAgreementFormData,
  IPastEmployeeStepOneFormData
} from '../../core/services/agreement/agreement.service';
import { IFormInputChangePayload } from '../abstract.components/abstract.form.component';
import {
  PEActivationStatusEnum,
  RecruitVerificationStatusEnum
} from './constants';
import { ValidationTypesEnum } from '../../core/services/employee/employee.service';
import { DepartmentsEnum } from '../abstract.components/constants';
import { EmployeeStatusEnum } from '../../core/services/employee/interfaces';

export const ActionTypes = {
  CHECK_AGREEMENT: type('[Agreement] check'),
  FETCH_SELECT_MODELS: type('[Agreement] fetch select models'),
  SET_SELECT_MODELS: type('[Agreement] save select models'),
  GET_STORAGE_DATA: type('[Agreement] get storage data'),
  SET_STORAGE_DATA: type('[Agreement] set storage data'),
  PREPARE_FORM_DATA: type('[Agreement] prepare form data'),
  STORE_AGREEMENT_DETAILS: type('[Agreement] store agreement details'),
  SET_DISCLOSURE_APPROVAL: type('[Agreement] set disclosure approval'),
  TOGGLE_DISCLOSURE_CHECKBOX: type('[Agreement] toggle disclosure checkbox'),
  PRE_POPULATE_FORM: type('[Agreement] pre populate form'),
  UPDATE_STEP: type('[Agreement] update step'),
  GO_TO_STEP: type('[Agreement] got to step'),
  SEARCH_SCHOOL: type('[Agreement] search school'),
  UPDATE_FORM_DATA: type('[Agreement] update form data'),
  FETCH_AGREEMENT_TERMS: type('[Agreement] fetch agreement terms'),
  FETCH_PAST_EMPLOYEE_FORM_DATA: type('[Agreement] fetch past employee form data'),
  STORE_PAST_EMPLOYEE_FORM_DATA: type('[Agreement] store past employee form data'),
  STORE_IS_EMERGENCY_EXIST: type('[Agreement] store is emergency exist'),
  SET_AGREEMENT_TERMS: type('[Agreement] save agreement terms'),
  SUBMIT_AGREEMENT: type('[Agreement] submit agreement form'),
  UPDATE_WELCOME_FORM_DATA: type('[Agreement] update welcome page form data'),
  WELCOME_CHECK_STEP_ONE: type('[Agreement] welcome check step one'),
  WELCOME_COMPLETE_STEP_ONE: type('[Agreement] welcome complete step one'),
  WELCOME_CHECK_STEP_TWO: type('[Agreement] welcome check step two'),
  WELCOME_SAVE_EMPLOYEE_ID: type('[Agreement] welcome save employee id'),
  WELCOME_UPDATE_CURRENT_STEP: type('[Agreement] welcome update current step'),
  WELCOME_COMPLETE_VERIFICATION: type('[Agreement] welcome complete verification'),
  SEARCH_STATE: type('[Agreement] search state'),
  SHOW_WELCOME_STEP_TWO_ERROR: type('[Agreement] show welcome step two error'),
  VALIDATE_SSN_AND_DOB: type('[Agreement] validate ssn and dob'),
  STORE_SSN_AND_DOB_VALIDITY: type('[Agreement] store ssn and dob validity'),
  SET_SSN_AND_DOB_LOADING: type('[Agreement] set ssn and dob loading'),
  AGREEMENT_LOAD_END: type('[Agreement] agreement load end'),
  FETCHING_END: type('[Agreement] agreement fetching end'),
  RETRY_STEP_ONE_DATA: type('[Agreement] agreement retry step one data'),
  UPDATE_SET_PASSWORD_FORM_DATA: type('[Agreement] update set password form data'),
  UPDATE_SET_PASSWORD_RULES: type('[Agreement] update set password rules'),
  SEND_NEW_PASSWORD: type('[Agreement] send new password'),
  SET_SIGNER_DATA: type('[Agreement] set signer data'),
  TRANSFER_CONTACT_TO_PAST_EMPLOYEE: type('[Agreement] transfer contact to past employee'),
  SET_TIP_STATE: type('[Agreement] set tip state'),
  SET_FIELD_LOADING_STATE: type('[Agreement] set field loading state'),
  VALIDATE_PHONE: type('[Agreement] validate phone'),
  SET_PHONE_VALIDATION: type('[Agreement] set phone validation'),
  INITIAL_PHONE_VALIDATION: type('[Agreement] initial phone validation'),
  VALIDATE_ADDRESS: type('[Agreement] validate address'),
  SET_ADDRESS_VALIDATION: type('[Agreement] set address validation'),
  GET_EMPLOYEE_STATUS: type('[Agreement] get founded employee status'),
  SET_FOUNDED_EMPLOYEE_STATUS: type('[Agreement] set founded employee status'),
  CONVERT_EMPLOYEE: type('[Agreement] convert employee'),
  HANDLE_EMPLOYEE_VERIFICATION: type('[Agreement] handle employee varification'),
  CLEAR_WELCOME_STEP_ERROR: type('[Agreement] clear welcome step error'),
  USER_VERIFICATION_COMPLETED: type('[Agreement] user verification completed'),
  RESET_AGREEMENT: type('[Agreement] reset'),
  GUARD_AGREEMENT: type ('[Agreement] guard')
};

export class CheckAgreement implements Action {
  public type = ActionTypes.CHECK_AGREEMENT;

  constructor(public payload: {
    id: any
  }) {
  }
}

export class FetchAgreementOptions implements Action {
  public type = ActionTypes.FETCH_SELECT_MODELS;

  constructor(public payload: string) {
  }
}

export class SaveSelectModels implements Action {
  public type = ActionTypes.SET_SELECT_MODELS;

  constructor(public payload: any) {
  }
}

export class PrepareFormData implements Action {
  public type = ActionTypes.PREPARE_FORM_DATA;

  constructor(public payload: IAgreementFormData) {
  }
}

export class StoreAgreementDetails implements Action {
  public type = ActionTypes.STORE_AGREEMENT_DETAILS;

  constructor(public payload: {
    userType: UserTypeEnum;
    employeeId: number;
    country: string,
    publicKey: string,
    seasonName: string,
    signerName: string
    signerLastName: string,
    signerPreferredName: string,
    signerEmail: string;
    isSigned: boolean;
    hasSetPassword: boolean;
    notificationType: NotificationTypesEnum;
    pastEmployeeStatus: PEActivationStatusEnum;
    department: DepartmentsEnum;
  }) {}
}

export class SetDisclosureApproval implements Action {
  public type = ActionTypes.SET_DISCLOSURE_APPROVAL;

  constructor(public payload: boolean | null) {
  }
}

export class ToggleDisclosureCheckbox implements Action {
  public type = ActionTypes.TOGGLE_DISCLOSURE_CHECKBOX;

  constructor(public payload?: any) {
  }
}

export class PrepopulateAgreementForm implements Action {
  public type = ActionTypes.PRE_POPULATE_FORM;

  constructor(public payload: {
    stepOne: IStepOneFormData,
    stepTwo: IStepTwoFormData
  }) {
  }
}

export class LoadAgreement implements Action {
  public type = ActionTypes.GET_STORAGE_DATA;

  constructor(public payload: string[]) {
  }
}

export class InitAgreementForm implements Action {
  public type = ActionTypes.SET_STORAGE_DATA;

  constructor(public payload: any) {
  }
}

export class StepUpdate implements Action {
  public type = ActionTypes.UPDATE_STEP;

  constructor(public payload: {
    data: any,
    step: string
  }) {
  }
}

export class FormInputUpdate implements Action {
  public type = ActionTypes.UPDATE_FORM_DATA;

  constructor(public payload: {
    fieldId: string,
    value: any
  }) {
  }
}

export class ChangeCurrentStep implements Action {
  public type = ActionTypes.GO_TO_STEP;

  constructor(public payload: string) {
  }
}

export class SearchSchool implements Action {
  public type = ActionTypes.SEARCH_SCHOOL;

  constructor(public payload: string) {
  }
}

export class FetchAgreementTerms implements Action {
  public type = ActionTypes.FETCH_AGREEMENT_TERMS;

  constructor(public payload: string) {
  }
}

export class FetchPastEmployeeFormData implements Action {
  public type = ActionTypes.FETCH_PAST_EMPLOYEE_FORM_DATA;

  constructor(public payload?: any) {
  }
}

export class StorePastEmployeeFormData implements Action {
  public type = ActionTypes.STORE_PAST_EMPLOYEE_FORM_DATA;

  constructor(public payload: IPastEmployeeStepOneFormData) {
  }
}

export class SaveAgreementTerms implements Action {
  public type = ActionTypes.SET_AGREEMENT_TERMS;

  constructor(public payload: string) {
  }
}

export class SubmitAgreementForm implements Action {
  public type = ActionTypes.SUBMIT_AGREEMENT;

  constructor(public payload?: any) {
  }
}

export class UpdateWelcomeFormData implements Action {
  public type = ActionTypes.UPDATE_WELCOME_FORM_DATA;

  constructor(public payload: IFormInputChangePayload) {
  }
}

export class WelcomeCheckStepOne implements Action {
  public type = ActionTypes.WELCOME_CHECK_STEP_ONE;

  constructor(public payload?: any) {
  }
}

export class WelcomeCompleteStepOne implements Action {
  public type = ActionTypes.WELCOME_COMPLETE_STEP_ONE;

  constructor(public payload?: {
    nextStep?: boolean,
    employeeId?: number,
    replaceUrl?: boolean
  }) {
  }
}

export class WelcomeCheckStepTwo implements Action {
  public type = ActionTypes.WELCOME_CHECK_STEP_TWO;

  constructor(public payload?: any) {
  }
}

export class WelcomeSaveEmployeeId implements Action {
  public type = ActionTypes.WELCOME_SAVE_EMPLOYEE_ID;

  constructor(public payload: number) {
  }
}

export class UpdateWelcomeCurrentStep implements Action {
  public type = ActionTypes.WELCOME_UPDATE_CURRENT_STEP;

  constructor(public payload: string) {
  }
}

export class CompleteWelcomeVerification implements Action {
  public type = ActionTypes.WELCOME_COMPLETE_VERIFICATION;

  constructor(public payload?: any) {
  }
}

export class AgreementSearchState implements Action {
  public type = ActionTypes.SEARCH_STATE;

  constructor(public payload: string) {
  }
}

export class ShowWelcomeStepTwoError implements Action {
  public type = ActionTypes.SHOW_WELCOME_STEP_TWO_ERROR;

  constructor(public payload?: any) {
  }
}

export class StoreIsEmergencyContactsExist implements Action {
  public type = ActionTypes.STORE_IS_EMERGENCY_EXIST;

  constructor(public payload?: IIsEmergencyContactsExist) {
  }
}

export class ValidateSsnAndDob implements Action {
  public type = ActionTypes.VALIDATE_SSN_AND_DOB;

  constructor(public payload: {
    ssn?: string;
    sin?: string;
    captcha: string;
  }) {
  }
}

export class StoreSsnAndDobValidity implements Action {
  public type = ActionTypes.STORE_SSN_AND_DOB_VALIDITY;

  constructor(public payload: {
    valid: boolean;
    message: any;
  }) {
  }
}

export class AgreementLoadEnd implements Action {
  public type = ActionTypes.AGREEMENT_LOAD_END;

  constructor(public payload: string) {
  }
}

export class FetchingEnd implements Action {
  public type = ActionTypes.FETCHING_END;

  constructor(public payload: {
    field: FetchingPropsEnum,
    status: FetchingStatusEnum,
  }) {
  }
}

export class RetryStepOneData implements Action {
  public type = ActionTypes.RETRY_STEP_ONE_DATA;

  constructor(public payload?: any) {
  }
}

export class UpdateSetPasswordFormData implements Action {
  public type = ActionTypes.UPDATE_SET_PASSWORD_FORM_DATA;

  constructor(public payload: IFormInputChangePayload) {
  }
}

export class SendNewPassword implements Action {
  public type = ActionTypes.SEND_NEW_PASSWORD;

  constructor(public payload?: any) {
  }
}

export class SetSignerData implements Action {
  public type = ActionTypes.SET_SIGNER_DATA;

  constructor(public payload: {
    userName: string;
    recruitName: string;
    recruitEmail: string;
  }) {
  }
}

export class TransferContactToPastEmployee implements Action {
  public type = ActionTypes.TRANSFER_CONTACT_TO_PAST_EMPLOYEE;

  constructor(public payload?: any) {
  }
}

export class SetTipState implements Action {
  public type = ActionTypes.SET_TIP_STATE;

  constructor(public payload: {
    type: TipTypesEnum,
    state: boolean
  }) {
  }
}

export class SetFieldLoadingState implements Action {
  public type = ActionTypes.SET_FIELD_LOADING_STATE;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    isLoading: boolean
  }) {
  }
}

export class ValidatePhone implements Action {
  public type = ActionTypes.VALIDATE_PHONE;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    phone: string
  }) {
  }
}

export class SetPhoneValidation implements Action {
  public type = ActionTypes.SET_PHONE_VALIDATION;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    valid: ValidationTypesEnum
  }) {
  }
}

export class InitialPhoneValidation implements Action {
  public type = ActionTypes.INITIAL_PHONE_VALIDATION;

  constructor(public payload?: any) {
  }
}

export class ValidateAddress implements Action {
  public type = ActionTypes.VALIDATE_ADDRESS;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    address: IAddressFields
  }) {
  }
}

export class SetAddressValidation implements Action {
  public type = ActionTypes.SET_ADDRESS_VALIDATION;

  constructor(public payload: {
    address: IValidatedAddressFields,
    valid: any,
    city: any,
    state: any
  }) {
  }
}

export class GetEmployeeStatus implements Action {
  public type = ActionTypes.GET_EMPLOYEE_STATUS;

  constructor(public payload: number) {
  }
}

export class SetFoundedEmployeeStatus implements Action {
  public type = ActionTypes.SET_FOUNDED_EMPLOYEE_STATUS;

  constructor(public payload: EmployeeStatusEnum) {
  }
}

export class ConvertEmployee implements Action {
  public type = ActionTypes.CONVERT_EMPLOYEE;

  constructor(public payload: string) {
  }
}

export class ClearWelcomeStepError implements Action {
  public type = ActionTypes.CLEAR_WELCOME_STEP_ERROR;

  constructor(public payload?: boolean) {
  }
}

export class UserVerificationCompleted implements Action {
  public type = ActionTypes.USER_VERIFICATION_COMPLETED;

  constructor(public payload?: boolean) {
  }
}

export class ResetAgreement implements Action {
  public type = ActionTypes.RESET_AGREEMENT;

  constructor(public payload?: boolean) {
  }
}

export class HandleEmployeeVarification implements Action {
  public type = ActionTypes.HANDLE_EMPLOYEE_VERIFICATION;

  constructor(public payload: {
    publicKey: string,
    isPastEmployee: boolean,
    status: RecruitVerificationStatusEnum | boolean
  }) {
  }
}

export class GuardAgreement implements Action {
  public type = ActionTypes.GUARD_AGREEMENT;

  constructor(public payload: boolean) {
  }
}

export type TypeAction =
  CheckAgreement |
  FetchAgreementOptions |
  SaveSelectModels |
  LoadAgreement |
  InitAgreementForm |
  StepUpdate |
  FormInputUpdate |
  ChangeCurrentStep |
  SearchSchool |
  FetchAgreementTerms |
  FetchPastEmployeeFormData |
  StorePastEmployeeFormData |
  SaveAgreementTerms |
  SubmitAgreementForm |
  PrepareFormData |
  StoreAgreementDetails |
  SetDisclosureApproval |
  ToggleDisclosureCheckbox |
  PrepopulateAgreementForm |
  UpdateWelcomeFormData |
  WelcomeCheckStepOne |
  WelcomeCompleteStepOne |
  WelcomeCheckStepTwo |
  UpdateWelcomeCurrentStep |
  CompleteWelcomeVerification |
  ShowWelcomeStepTwoError |
  ValidateSsnAndDob |
  StoreSsnAndDobValidity |
  AgreementLoadEnd |
  FetchingEnd |
  UpdateSetPasswordFormData |
  SendNewPassword |
  SetSignerData |
  TransferContactToPastEmployee |
  SetTipState |
  SetFieldLoadingState |
  ValidatePhone |
  SetPhoneValidation |
  InitialPhoneValidation |
  ValidateAddress |
  SetAddressValidation |
  GetEmployeeStatus |
  SetFoundedEmployeeStatus |
  ConvertEmployee |
  ClearWelcomeStepError |
  StoreIsEmergencyContactsExist |
  AgreementSearchState |
  WelcomeSaveEmployeeId |
  UserVerificationCompleted |
  ResetAgreement |
  GuardAgreement;
